import React from 'react';
import './Banner.scss';
import YoutubeEmbed from './YoutubeEmbed';

function Banner() {
  return (
    <div className='banner'> 
      {/* <span className='blurr-circle-1'></span> */}
      <span className='blurr-circle-2'></span>
        <div className='banner-content'>
            <h1>Growth Transformation Innovation </h1>
            <p>Amicus Growth Advisors - Your Growth Partners</p>
            <a href="https://drive.google.com/file/d/1J5Y5Bwt6TeEcsM6lgr16bmht2CvOW4_q/view?usp=sharing" target="_blank">
                    <button className='corporate-deck'>Corporate Deck</button>
             </a>
            {/* <button>Schedul Your Consult </button> */}
            
            <div className='banner-video'>
            
            <YoutubeEmbed embedId="t9cWeL7CtkU" /> 
            </div>
 
        </div>
        
        
        
    </div>
  )
}

export default Banner