import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ScrollToTop from './ScrollToTop'; 
import Home from './components/home/Home';
import Career from './components/CareerPage/Career';
import ServicePage from './components/services/ServicePage';
import PageTop from './components/Assets/pageTop/PageTop';
import TermsPage from './components/termsPage/TermsPage';
import Navbar from './components/Assets/Navbar/Navbar';
import FooterNew from './components/Assets/FooterNew/FooterNew';
import AboutPage from './components/AboutPage/AboutPage';
import Logistics from './components/IndustryPage/Logistics';
import BlogPage from './components/BlogPage/BlogPage';
import ContactPage from './components/ContactPage/ContactPage';
import BlogSingle from './components/BlogPage/BlogSingle';
import ReportPage from './components/ReportPage/ReportPage';
import DiagnosticPage from './components/DiagnosticPage/DiagnosticPage';
import Retail from './components/IndustryPage/Retail';
import Hospitality from './components/IndustryPage/Hospitality';
import { PopupWidget } from "react-calendly";
import ScrollTopButton from './components/ScrollTopButton';
import D2C from './components/IndustryPage/D2C';
import Manufacturing from './components/IndustryPage/Manufacturing';
import Edtech from './components/IndustryPage/Edtech';
import ErrorPage from './ErrorPage';
import Blog from './components/Blog/Blog';
import SinglePost from './components/Blog/SinglePost';
import ReportSingle from './components/ReportPage/ReportSingle';
import MyForm from './components/ContactPage/MyForm';
import StartupPage from './components/StartupPage/StartupPage';
import BusinessTransformation from './components/services/BusinessTransformation';
import BoardAdvisory from './components/services/BoardAdvisory';
import VirtualCFO from './components/services/VirtualCFO';
import SMEIPO from './components/services/SMEIPO';
import BusinessDiagnostic from './components/services/BusinessDiagnostic';
import StartupAdvisory from './components/services/StartupAdvisory';
import Merger from './components/services/Merger';
import InvestorRelations from './components/services/InvestorRelations';
import FPA from './components/services/FPA';
import ESGAdvisory from './components/services/ESGAdvisory';
import Plantation from './components/Plantation/Plantation';
import {Helmet} from "react-helmet";
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import BusinessDiagnosticPune from './components/services/BusinessDiagnosticPune';
import BusinessDiagnosticDelhi from './components/services/BusinessDiagnosticDelhi';
import BusinessDiagnosticHyd from './components/services/BusinessDiagnosticHyd';
import SMEIPODelhi from './components/services/SMEIPODelhi';
import SMEIPOPune from './components/services/SMEIPOPune';
import SMEIPOBanglore from './components/services/SMEIPOBanglore';
import SMEIPOHyderabad from './components/services/SMEIPOHyd';



function App() {
  return (
    <div className="App">

        <Helmet>
            <meta charSet="utf-8" />
            <link rel="canonical" href="https://www.amicusllp.com" />
        </Helmet>


        <Router >
          <ScrollToTop />
        <div className="App">
        <ScrollTopButton />
        

        <Navbar />
              
              <Routes basename='/index.html'> 
                  
                  <Route path="/" element={<Home />} ></Route> 
                  <Route path="/form" element={<MyForm />} ></Route>
                  <Route path="/grow-trees" element={<Plantation />} ></Route>
                  <Route path="/services" element={<ServicePage />} ></Route>
                  <Route path="/services/business-transformation-consultants" element={<BusinessTransformation />} ></Route>
                  <Route path="/services/investor-relations" element={<InvestorRelations />} ></Route>
                  <Route path="/services/business-diagnostics-and-strategic-business-consultant" element={<BusinessDiagnostic />} ></Route>
                  <Route path="/services/business-diagnostics-and-strategic-business-consultant-pune" element={<BusinessDiagnosticPune />} ></Route>
                  <Route path="/services/business-diagnostics-and-strategic-business-consultant-delhi" element={<BusinessDiagnosticDelhi />} ></Route>
                  <Route path="/services/business-diagnostics-and-strategic-business-consultant-hyderabad" element={<BusinessDiagnosticHyd />} ></Route>
                  <Route path="/services/business-diagnostics-and-strategic-business-consultant-banglore" element={<BusinessDiagnosticHyd />} ></Route>
                  <Route path="/services/sme-ipo-consultants" element={<SMEIPO />} ></Route>
                  <Route path="/services/sme-ipo-consultants-delhi" element={<SMEIPODelhi />} ></Route>
                  <Route path="/services/sme-ipo-consultants-pune" element={<SMEIPOPune />} ></Route>
                  <Route path="/services/sme-ipo-consultants-banglore" element={<SMEIPOBanglore />} ></Route>
                  <Route path="/services/sme-ipo-consultants-hyderabad" element={<SMEIPOHyderabad />} ></Route>
                  <Route path="/services/financial-planning-analysis" element={<FPA />} ></Route>
                  <Route path="/services/startup-consulting-and-advisory-services" element={<StartupAdvisory />} ></Route>
                  <Route path="/services/board-advisory" element={<BoardAdvisory />} ></Route>
                  <Route path="/services/virtual-cfo" element={<VirtualCFO />} ></Route>
                  <Route path="/services/merger-acquisitions" element={<Merger />} ></Route>
                  <Route path="/services/esg-advisory" element={<ESGAdvisory />} ></Route>
                  
                  <Route path="/company" element={<AboutPage />} ></Route>
                  <Route path="/logistics" element={<Logistics />} ></Route>
                  <Route path="/d2c" element={<D2C />} ></Route>
                  <Route path="/retail" element={<Retail />} ></Route>
                  <Route path="/manufacturing" element={<Manufacturing />} ></Route>
                  <Route path="/hospitality" element={<Hospitality />} ></Route>
                  <Route path="/edtech" element={<Edtech />} ></Route>
                  <Route path="/blog" element={<BlogPage />} ></Route>
                  <Route path="/blog/:slug" element={<SinglePost />} ></Route>
                  {/* <Route path="/blog" element={<Blog />} ></Route> */}
                  <Route path="*" element={<ErrorPage />} ></Route>
                  <Route path="/Why conduct a marketing audit for your small business" element={<BlogSingle />} ></Route>
                  <Route path="/contact" element={<ContactPage />} ></Route>
                  <Route path="/report" element={<ReportPage />} ></Route>
                  <Route path="/report/:slug" element={<ReportSingle />} ></Route>
                  <Route path="/business-diagnostic" element={<DiagnosticPage />} ></Route>
                  <Route path="/startup-funding" element={<StartupPage />} ></Route>
                  <Route path="/terms" element={<TermsPage />} ></Route>
                  <Route path="/career" element={<Career />} ></Route> 
                  <Route path="/pagetop" element={<PageTop />} ></Route>
                  <Route path="/termspage" element={<TermsPage />} ></Route>
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} ></Route>
                  
              </Routes>

                   

              <PopupWidget
                  url="https://calendly.com/sanjeev-jain/connect-with-sanjeev?month=2023-07"
                  rootElement={document.getElementById("root")}
                  text="Get Free Consultation"
                  textColor="#ffffff"
                  color="#566EC8"

               />
              
          <FooterNew />
        </div>
          
        </Router>
        
     
    </div>
  );
}

export default App;
